import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import "../data/sections-fragment";
export const query = graphql`
  query {
    mdx(slug: { eq: "" }) {
      slug
      frontmatter {
        title
        footer {
          showContactFooter: show_contact_footer
          showContactColumn: show_contact_column
          spacing {
            ...SpacingFragment
          }
        }
        backgrounds {
          ...BackgroundFragment
        }
        sections {
          ...MdxFrontmatterSectionsFields
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Startseite",
  "footer": {
    "show_contact_footer": false,
    "show_contact_column": true,
    "spacing": {
      "top": ["48", null, "72", null, "96"]
    }
  },
  "backgrounds": [{
    "rows": "1 / 3",
    "gradient": "linear-gradient(to-b, gray.50, rgba(255,255,255,0))",
    "spacing": {
      "top": ["33vh", null, "35vh", null, "45vh"]
    }
  }],
  "sections": [{
    "type": "header",
    "slug": "header",
    "header_type": "alpha",
    "title": "<span>Wir bauen Ihre pass&shy;genaue <strong>Wunsch&shy;garage</strong> aus Beton</span>",
    "image": {
      "src": "images/highlight-startseite.jpg",
      "alt": "Wunschgarage",
      "fit": "cover",
      "position": "50% 65%"
    }
  }, {
    "type": "cards",
    "slug": "themen",
    "spacing": {
      "top": ["14", null, "28", null, "24"],
      "bottom": ["40", null, "60", null, "72"]
    },
    "cards_type": "alpha",
    "columns": [{
      "type": "content",
      "content": "Wir sind ein Familienunternehmen aus Freiburg und Spezialisten für Betongaragen nach Maß. Bei uns bekommen Sie ganzheitliche Lösungen: individuell, passgenau, besonders.\n\nWir begleiten Sie von der Planung bis zu dem Moment, wo Sie Ihre fertige Garage das erst Mal sehen und wahrnehmen, wie wunderbar sie Ihr Grundstück und die bestehende Architektur ergänzt.\n\nFür uns ist eine Garage nicht nur ein Ort, an dem sie Ihr Auto und andere Dinge unterbringen. Für uns ist eine Garage Ausdruck von Individualität. Der Raum, der Ihr Wohnumfeld stimmig ergänzt. Der Raum, der passgenau von uns für Sie entwickelt wird."
    }, {
      "type": "content",
      "title": "Garagen nach Maß",
      "image": {
        "src": "images/highlight-garagen-nach-mass.jpg",
        "alt": "Garagen nach Maß",
        "fit": "cover",
        "position": "50% 50%"
      },
      "content": "Grundstücke sind unterschiedlich. Die baulichen Anforderungen an die Garage somit meist auch. Wir können Ihnen die passgenaue Garage für alle Gegebenheiten planen und bauen: für Hanglagen, für Nischen und Ecken, zum Überfahren, für schräge Grundstücksgrenzen, in Überhöhe – es bleiben keine Wünsche offen.",
      "cta": {
        "url": "/garagen-nach-mass/",
        "label": "Mehr entdecken"
      }
    }, {
      "type": "content",
      "title": "Fertiggaragen",
      "image": {
        "src": "images/highlight-fertiggaragen.jpg",
        "alt": "Fertiggaragen",
        "fit": "cover",
        "position": "50% 50%"
      },
      "content": "Manchmal reichen einfache Lösungen. Auch Fertiggaragen vom Fachmann können gute, kostengünstige Lösungen sein. Wir liefern Ihnen diese Varianten fix fertig auf Ihr Grundstück. Dabei haben Sie die Wahl zwischen Einzel,- Doppel oder Reihengaragen in verschiedenen Standard-Maßen.",
      "cta": {
        "url": "/fertiggaragen/",
        "label": "Mehr entdecken"
      }
    }]
  }, {
    "type": "cards",
    "slug": "themen-2",
    "cards_type": "beta",
    "background_image": {
      "src": "images/beton.jpg",
      "position": "100% 100%"
    },
    "columns": [{
      "type": "social_media_post",
      "post": "src/data/social-media-posts/youtube/09-01-23-besonderer-ort.yaml"
    }, {
      "type": "content",
      "title": "Wie wir planen",
      "content": "Beim Bau einer individuellen Garage ergeben sich vielfältige Fragestellungen. Wir beantworten solche Fragen jeden Tag und unterstützen Sie, damit alle baurechtlichen Grundlagen berücksichtigt werden, das Projekt optimal geplant wird und Ihre Wünsche bestmöglich umgesetzt werden.",
      "cta": {
        "url": "/leistung/#wie-wir-planen",
        "label": "Mehr erfahren"
      }
    }, {
      "type": "content",
      "title": "Wie wir bauen",
      "content": "Als Fachbetrieb ist für uns der fachgerechte Bau Ihrer Garage ein wichtiges Anliegen. Dieses sichern wir mit unserer Erfahrung und der Auswahl von hochwertigen Materialien ab - angefangen vom Beton über Putze und Farben bis hin zur Ausstattung und Dach-Beschichtung. Und auch die statische Berechnung ist bei uns mit inbegriffen.",
      "cta": {
        "url": "leistung/#wie-wir-bauen",
        "label": "Mehr erfahren"
      }
    }, {
      "type": "social_media_post",
      "post": "src/data/social-media-posts/facebook/07-12-2020-garage.yaml"
    }, {
      "type": "content",
      "title": "Für was wir als Unternehmen stehen",
      "content": "Wir lieben Herausausforderungen und die Suche nach der optimalen Lösung. Bei uns bekommen Sie von der Planung bis zur Umsetzung alles aus einer Hand.\n\nGemeinsam schaffen wir Werte – extern sichtbare mit unseren Projekten und intern gelebt durch unsere familiäre Firmenkultur. Wir sind stolz auf unser Team aus fachlich versierten Mitarbeitern, die unsere Ziele teilen und die passende berufliche Basis und Erfahrung mitbringen.",
      "cta": {
        "url": "/unternehmen/",
        "label": "Mehr erfahren"
      }
    }, {
      "type": "content",
      "title": "Versetzen von Garagen",
      "content": "Neues braucht Raum. Und manchmal muss man diesen erst schaffen. Wir vesetzten Ihre alte Garage am Stück. Dafür nutzen wir unseren speziellen LKW, der bis zu 16 Tonnen anheben, aufladen und wegbringen kann. So können Sie das gute Stück entweder auf ein neues Fundament setzen, an einen anderen Platz auf Ihrem Grundstück bringen oder direkt entsorgen lassen.",
      "cta": {
        "url": "leistung/#versetzen-von-garagen",
        "label": "Mehr erfahren"
      }
    }, {
      "type": "social_media_post",
      "post": "src/data/social-media-posts/youtube/14-11-11-garagen.yaml"
    }]
  }]
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      